<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="setting_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>订单中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">发票中心</el-breadcrumb-item>
              <el-breadcrumb-item>发票详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_rights">
                <div class="box_right_top">发票详情</div>
                <div class="box_right_cent" v-for="(item,index) in invoiceList" :key="index">
                  <div class="drawBill-box">
                    <div class="drawBill-top flex-start">
                      <div>订单开票信息</div>
                      <div class="modify-btn" @click="listData()" v-show="modeled!=1">修改信息</div>
                    </div>
                    <div class="drawBill-top-box">
                      <div class="box-top flex-start">
                        <div>发票信息</div>
                        <div class="top-number">订单号：{{item.orderNo}}</div>
                      </div>
                      <div class="flex-start box-cent">
                        <div class="drawBill-start">发票类型</div>
                        <div class="">{{item.typeName}}</div>
                      </div>
                      <div class="flex-start box-cent">
                        <div class="drawBill-start">发票内容</div>
                        <div class="">{{item.content}}</div>
                      </div>
                      <div class="flex-start box-cent">
                        <div class="drawBill-start">发票抬头</div>
                        <div class="">{{item.companyName}}</div>
                      </div>
                        <div class="flex-start box-cent">
                            <div class="drawBill-start">纳税人识别号</div>
                            <div class="">{{item.taxpayerNo}}</div>
                        </div>
                        <div class="flex-start box-cent">
                            <div class="drawBill-start">地址电话</div>
                            <div class="">{{item.registeredAddress}}{{item.registeredPhone}}</div>
                        </div>
                        <div class="flex-start box-cent">
                            <div class="drawBill-start">开户行及账号</div>
                            <div class="">{{item.bankName}}{{item.bankAccount}}</div>
                        </div>
                      <div class="flex-start box-cent">
                        <div class="drawBill-start">开票方式</div>
                        <div class="">{{item.invoicingTypeName}}</div>
                      </div>
                    </div>
                    <div class="drawBill-text">
                      发票实开信息
                    </div>
                    <div class="invoice-cent">
                        <el-table
                        :data="tableData"
                        border :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                        :cell-style="{ textAlign: 'center' }"
                        style="width: 100%;text-align: center;">
                            <el-table-column
                            prop="state"
                            label="发票开具状态"
                            width="110">
                            <template slot-scope="scope">
                              {{stateName(scope.row.state)}}
                            </template>
                            </el-table-column>
                            <!-- <el-table-column
                            prop="invoiceType"
                            label="发票类型"
                            width="90">
                            <template slot-scope="scope">
                              <div v-if="scope.row.invoiceType==2">纸质发票</div>
                            </template>
                            </el-table-column> -->
                            <el-table-column
                            prop="create_time"
                            label="开票时间"
                            width="120">
                            </el-table-column>
                            <el-table-column
                            prop="invoiceAmount"
                            label="发票金额"
                            width="90">
                            </el-table-column>
                            <el-table-column
                            prop="invoiceCode"
                            label="发票代码"
                            min-width="160">
                            </el-table-column>
                            <el-table-column
                            prop="invoiceNo"
                            label="发票号码"
                            width="160">
                            </el-table-column>
                            <!-- <el-table-column
                            prop="mailingState"
                            label="邮寄状态">
                            <template slot-scope="scope">
                              {{mailingName(scope.row.mailingState)}}
                            </template>
                            </el-table-column> -->
                            <!-- <el-table-column
                            prop="addresss"
                            label="操作">
                            <template slot-scope="scope">
                              <div v-if="scope.row.mailingState==0">提醒邮寄</div>
                              <div v-else></div>
                            </template>
                            </el-table-column> -->
                        </el-table>
                    </div>
                    <div class="drawBill-bom">
                      <!-- <div class="bom-btn" @click="ApplyPaper()" v-show="model!=1">申请纸质发票</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-dialog title="修改发票信息" :visible.sync="dialogTableVisible">
                <el-table
                  :data="tableList"
                  :loaing="dialogTableLoading"
                  border
                  :header-cell-style="{
                      background: '#cccccc',
                      color: '#333',
                      textAlign: 'center',
                  }"
                  :cell-style="{
                      textAlign: 'center',
                  }"
                  style="
                      width: 100%;
                      text-align: center;
                  "
                  @current-change="
                      handleSelectionChange
                  "
                  highlight-current-row
              >
                  <el-table-column label="选择" width="70" center >
                      <template slot-scope="scope">
                          <div @click.stop.prevent="getCurrentRow(scope.$index,scope.row)">
                              <el-radio class="radio" v-model="radio" :label="scope.$index">&nbsp;</el-radio>
                          </div>
                          
                      </template>
                  </el-table-column>
                  <el-table-column
                      prop="invoiceHeader"
                      label="名称"
                      width="280"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="taxpayerNo"
                      label="纳税人识别号"
                      width="250"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="invoiceHeader"
                      label="地址电话"
                      width="450"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="bankAccount"
                      label="开户行及账号"
                      width="250"
                  >
                  </el-table-column>
              </el-table>
              <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogTableVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="editInvoice()" :loading="editInvoiceBtn">确 定</el-button>
              </span>
            </el-dialog>

          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { applydetails,applygoods } from "@/api/order/aftersale"
import { getrestInvoice,editInvoice } from "@/api/order/order"
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        invoiceList:[],//发票信息
        orderNo:'',//订单编号
        invoiceId:'',//发票id
        tableData: [],
        model:0,
        dialogTableVisible: false,
        dialogTableLoading: false,
        editInvoiceBtn: false,
        tableList:[],
        radio:"",
        invoiceConfigId:'',
        ConfigId:'',
        modeled:0
      }
  },
  computed: {
    stateName(){
      return (state) => {
        switch (state) {
          case 0:
            return "未开票";
          case 1:
            return "已开票";
          case 3:
            return "作废";
          case 4:
            return "已重开";
        }
      };
    },
    mailingName() {
      return (mailingState) => {
        switch (mailingState) {
            case 0:
                return "未寄出";
            case 1:
                return "已寄出";
        }
      };
    },
    
  },
  created() {
    
    // if(this.$route.query.item!=undefined){
    //   let list = JSON.parse(this.$route.query.item)
    //   this.invoiceId=list.id
    //   this.orderNo=list.orderNo
    //   this.invoiceList.push(list)
    //   this.tableData=list.infoList
    // }else{
    //   let result = JSON.parse(localStorage.getItem("prizeResult"))
    //   this.invoiceId=result.id
    //   this.orderNo=result.orderNo
    //   this.invoiceList.push(result)
    //   this.tableData=result.infoList
    // }
    let obj = JSON.parse(this.$route.query.item)
    // console.log(obj);
    this.invoiceId = obj.id
    let data = {
      "invoiceId": obj.id,
      "type": 2
    }
    applydetails(data).then( res => {
      if(res.code == '00000') {
        this.ConfigId = res.data.id
        this.orderNo = res.data.orderNo
        this.invoiceList.push(res.data)
        this.tableData = res.data.infoList
        if(res.data.infoList && res.data.infoList.length > 1){
          this.modeled = 1
        }
        this.demand()
      }
    })
    
  },
  methods:{
    ApplyPaper(){
      this.$router.push({ name: 'ApplyPaper',query:{orderNo:this.orderNo,invoiceId:this.invoiceId} })
    },
    goto(){
      this.$router.push({ name: 'InvoiceCenter'})
    },
    //查询有无申请商品
    demand(){
      let datas={
        "orderNo": this.orderNo,
        "type": 1
      }
      applygoods(datas).then((data)=>{
        if(data.code == 'E0001'){
          this.modeled = 1
          this.model = 1
        }
      })
    },
    listData(){
      this.dialogTableVisible = true
      this.dialogTableLoading = true
      let data = {
          id: "",
          name: "",
      };
      getrestInvoice(data).then((data) => {
          if(data&&data.code =='00000'){
            this.tableList=data.data
          }
          this.dialogTableLoading = false
      }).catch(() => {
        this.dialogTableLoading = false
      })
    },
    getCurrentRow(index,val){
      this.radio=index
      this.invoiceConfigId=val.id
    },
    handleSelectionChange(val) {
      console.log(val)
    },
    editInvoice(){
      this.editInvoiceBtn = true
      let data ={
        "invoiceConfigId": this.invoiceConfigId,
        "invoiceId": this.ConfigId
      }
      editInvoice(data).then(data=>{
        if(data&&data.code =="00000"){
          this.$message({
            type: "success",
            message: '修改成功!',
          });
          this.invoiceList=[]
          this.invoiceList.push(data.data)
          this.dialogTableVisible = false
        }else{
          this.$message({
            type: "error",
            message: data.desc,
          });
        }
        this.editInvoiceBtn = false
      }).catch( () => {
        this.editInvoiceBtn = false
      })
    }
  }

}
</script>

<style lang="scss">
.setting_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_rights{
    width: 87%;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .drawBill-box{
      padding: 15px 20px;
      .modify-btn{
        width: 100px;
        height: 30px;
        line-height: 30px;
        color: #CD9F49;
        border: 1px solid #CD9F49;
        text-align: center;
        font-size: 15px;
        margin: 20px;
        cursor: pointer;
      }
      .drawBill-top-box{
        border: 1px solid #999;
        border-bottom: none;
        font-size: 15px;
        .box-top{
          height: 40px;
          line-height: 30px;
          background: #d7d7d7;
          padding: 0 20px;
          border-bottom: 1px solid #999;
          .top-number{
            padding-left: 10px;
          }
        }
        .box-cent{
          border-bottom: 1px solid #999;
          height: 38px;
          line-height: 38px;
          .drawBill-start{
            width: 220px;
            border-right: 1px solid #999;
            padding-left: 20px;
            margin-right: 10px;
          }
        }
        
      }
      .drawBill-text{
        margin: 30px 0 20px;
      }
      .drawBill-bom{
        margin: 30px 0;
        .bom-btn{
          margin: 0 auto;
          width: 120px;
          height: 45px;
          line-height: 45px;
          color: #fff;
          background: #CD9F49;
          font-size: 15px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    
  }
  .el-dialog{
    width: 69.9%;
  }
}
</style>